// src/Header.js

import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { auth } from './firebase';
import { Link as RouterLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import BookIcon from '@mui/icons-material/Book';
import Brightness4Icon from '@mui/icons-material/Brightness4';

// Import your logo image
import logo from './assets/logo.png'; // Adjust the path as needed

const Header = ({ user, toggleColorMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    auth.signOut();
  };

  // State for user menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={0}
      sx={{ borderBottom: '1px solid #DBDBDB' }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left-aligned elements */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Library Icon/Button */}
          {user && (
            <IconButton
              color="inherit"
              component={RouterLink}
              to="/library"
              sx={{ mr: 1 }}
            >
              <BookIcon />
            </IconButton>
          )}
        </Box>

        {/* Centered Logo */}
        <Box
          component={RouterLink}
          to="/"
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Epic Tales Logo"
            sx={{ height: 40 }}
          />
        </Box>

        {/* Right-aligned elements */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Theme Toggle Button */}
          <IconButton color="inherit" onClick={toggleColorMode} sx={{ mr: 1 }}>
            <Brightness4Icon />
          </IconButton>

          {user ? (
            <>
              {/* User Menu */}
              <IconButton
                color="inherit"
                onClick={handleUserMenuClick}
                sx={{ p: 0 }}
              >
                <PersonIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleUserMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              {/* Login and Sign Up Buttons */}
              {isMobile ? (
                <IconButton
                  color="inherit"
                  component={RouterLink}
                  to="/login"
                  sx={{ mr: 1 }}
                >
                  <PersonIcon />
                </IconButton>
              ) : (
                <>
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/login"
                    startIcon={<LoginIcon />}
                    sx={{ mr: 1 }}
                  >
                    Login
                  </Button>
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/signup"
                    sx={{ mr: 1 }}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
