// src/DonationSuccess.js

import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const DonationSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract storyId from query parameters
  const queryParams = new URLSearchParams(location.search);
  const storyId = queryParams.get('storyId');

  const handleContinueStory = () => {
    navigate(`/story/${storyId}`);
  };

  const handleGoHome = () => {
    navigate('/');
  };

  const handleStartNewStory = () => {
    navigate('/genres');
  };

  const handleGoToLibrary = () => {
    navigate('/library');
  };

  return (
    <Box textAlign="center" mt={4}>
      <Typography variant="h4" gutterBottom>
        Thank You!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your donation has been successfully processed. We appreciate your
        support!
      </Typography>

      <Box mt={3}>
        {storyId && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleContinueStory}
            sx={{ mr: 2 }}
          >
            Continue Story
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartNewStory}
          sx={{ mr: 2 }}
        >
          Start New Story
        </Button>
        <Button variant="outlined" color="primary" onClick={handleGoHome}>
          Go to Home
        </Button>
      </Box>
    </Box>
  );
};

export default DonationSuccess;
