// src/GenreList.js

import React, { useState, useEffect } from 'react';
import { firestore } from './firebase';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CircularProgress, // Import CircularProgress
} from '@mui/material';

const GenreList = () => {
  const [genres, setGenres] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const genresSnapshot = await firestore.collection('genres').get();
        const genresData = genresSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setGenres(genresData);
        setLoading(false); // Set loading to false after fetching
      } catch (error) {
        console.error('Error fetching genres:', error);
        setLoading(false); // Ensure loading is false even if there's an error
      }
    };

    fetchGenres();
  }, []);

  const handleGenreClick = (genreId) => {
    navigate(`/subgenres/${genreId}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Choose a Genre
      </Typography>
      <Grid container spacing={2} id="genres">
        {genres.map((genre) => (
          <Grid
            item
            xs={6} // 2 columns on extra-small screens
            sm={4} // 3 columns on small screens
            md={3} // 4 columns on medium screens
            key={genre.id}
          >
            <Card>
              <CardActionArea onClick={() => handleGenreClick(genre.id)}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  paddingTop: '100%', // 1:1 aspect ratio
                }}
              >
                <CardMedia
                  component="img"
                  image={genre.imageUrl || "/placeholder.jpg"}
                  alt={genre.name}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
                <CardContent>
                <Typography
                variant="h6"
                align="center"
                sx={{ p: 1, fontWeight: 'bold' }}
              >
                {genre.name}
              </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {genre.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GenreList;
