// src/Story.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
} from '@mui/material';
import { firestore } from './firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import { useParams, useNavigate } from 'react-router-dom';
import DonateButton from './DonateButton';

const Story = ({ user, onBack, onStartNewStory }) => {
  const [story, setStory] = useState('');
  const [choices, setChoices] = useState([]);
  const [summary, setSummary] = useState('');
  const [chapterCount, setChapterCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isStoryCompleted, setIsStoryCompleted] = useState(false);
  const [title, setTitle] = useState('');
  const { storyId, genreId, subgenreId } = useParams();
  const [genre, setGenre] = useState(null);
  const [subgenre, setSubgenre] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for data fetching
  const navigate = useNavigate(); // For navigation
  const [rating, setRating] = useState(0);

  const shareUrl = window.location.href; // Or a specific URL

  // Function to open the confirmation dialog
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  // Function to close the confirmation dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Function to confirm starting a new story
  const handleConfirmStartNewStory = () => {
    handleStartNewStory();
    setDialogOpen(false);
  };

  // Function to start a new story
  const handleStartNewStory = () => {
    // Clear state variables
    setStory('');
    setChoices([]);
    setSummary('');
    setChapterCount(0);
    setIsStoryCompleted(false);
    setTitle('');
    setGenre(null);
    setSubgenre(null);
    setLoading(true);

    // Remove saved story from localStorage
    localStorage.removeItem('epicTalesStory');

    // Navigate back to genres or home page
    navigate('/genres');
  };

  // Function to decide if the next chapter should be the final one
  const shouldEndStory = (currentChapter) => {
    if (currentChapter < 5) {
      return false;
    } else {
      const chapter = currentChapter + 1; // Next chapter number
      const baseProbability = 10; // Starting probability at chapter 6
      const probabilityIncrement = 2; // Increment per chapter after chapter 6
      const chapterOffset = 6; // Chapter where probability starts

      // Calculate probability
      const probability =
        baseProbability + (chapter - chapterOffset) * probabilityIncrement;

      // Ensure probability doesn't exceed 100%
      const finalProbability = Math.min(probability, 100);

      // Generate a random number between 0 and 100
      const randomChance = Math.random() * 100;

      // Determine if the story should end
      return randomChance < finalProbability;
    }
  };

  // Function to generate the story
  const generateStory = async (selectedChoice, isFirstChapter = false) => {
    if (!genre || !subgenre) {
      console.error('Genre or subgenre is undefined');
      setError('Genre or subgenre is undefined');
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      console.log('Generating story with genre:', genre, 'subgenre:', subgenre);

      // Determine if the next chapter should be the final one
      const isFinalChapter = shouldEndStory(chapterCount);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/story/generate`,
        {
          selectedChoice,
          summary,
          genre,
          subgenre,
          isFirstChapter,
          isFinalChapter, // Pass this to the backend
        }
      );

      const { story: newStoryPart, title: newTitle } = response.data;

      if (isFirstChapter && newTitle) {
        setTitle(newTitle);
      }

      // Extract choices
      const generatedChoices = extractChoicesFromText(newStoryPart);

      // Remove choices from the story text
      const storyTextWithoutChoices = newStoryPart
        .replace(/\*\*Choice\s*\d+\*\*:\s.*?(\n|$)/gi, '')
        .trim();

      // Update the story and summarize
      if (storyTextWithoutChoices) {
        setStory((prevStory) => {
          const updatedStory = prevStory + '\n\n' + storyTextWithoutChoices;
          // Summarize the updated story
          summarizeStory(updatedStory);
          return updatedStory;
        });
      } else {
        setError('No story content was generated. Please try again.');
        setSnackbarOpen(true);
      }

      // Update chapter count
      setChapterCount((prevChapterCount) => prevChapterCount + 1);

      // Update story completion status
      if (isFinalChapter) {
        setIsStoryCompleted(true);
        setChoices([]); // No more choices if the story has ended
      } else {
        // Update choices state
        setChoices(generatedChoices);
      }

      // Summarize the updated story
      await summarizeStory(story + '\n\n' + storyTextWithoutChoices);

      // Save story progress
      await saveStoryProgress();
    } catch (error) {
      console.error('Error generating story:', error);
      setError(
        error.response?.data?.error ||
          'Failed to generate the story. Please try again.'
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const summarizeStory = useCallback(async (fullStory) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/story/summarize`,
        {
          story: fullStory,
        }
      );

      const newSummary = response.data.summary;

      // Update the summary state
      setSummary(newSummary);
    } catch (error) {
      console.error('Error generating summary:', error);
      setError('Failed to generate the summary. Please try again.');
      setSnackbarOpen(true);
    }
  }, []);

  // Load story progress on component mount
  useEffect(() => {
    const loadStoryProgress = async () => {
      if (storyId && user) {
        // Load story from Firestore
        try {
          const storyDoc = await firestore
            .collection('users')
            .doc(user.uid)
            .collection('stories')
            .doc(storyId)
            .get();

          if (storyDoc.exists) {
            const storyData = storyDoc.data();
            setStory(storyData.story);
            setSummary(storyData.summary);
            setChapterCount(storyData.chapterCount);
            setTitle(storyData.title);
            setIsStoryCompleted(storyData.isCompleted);
            setGenre(storyData.genre);
            setSubgenre(storyData.subgenre);
            if (storyData.rating) {
              setRating(storyData.rating);
            }
          } else {
            console.error('Story not found.');
            setError('Story not found.');
          }
        } catch (error) {
          console.error('Error fetching story:', error);
          setError('Error fetching story.');
        } finally {
          setLoading(false);
        }
      } else if (genreId && subgenreId) {
        // Fetch genre and subgenre details
        try {
          const genreDoc = await firestore
            .collection('genres')
            .doc(genreId)
            .get();
          const subgenreDoc = await firestore
            .collection('genres')
            .doc(genreId)
            .collection('subgenres')
            .doc(subgenreId)
            .get();

          if (genreDoc.exists && subgenreDoc.exists) {
            const genreData = { id: genreDoc.id, ...genreDoc.data() };
            const subgenreData = {
              id: subgenreDoc.id,
              ...subgenreDoc.data(),
            };
            setGenre(genreData);
            setSubgenre(subgenreData);

            // Wait for state updates before calling generateStory
            await new Promise((resolve) => setTimeout(resolve, 0));

            // Wait for genre and subgenre to be set
            setLoading(false);
          } else {
            console.error('Genre or Subgenre not found.');
            setError('Genre or Subgenre not found.');
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching genre or subgenre:', error);
          setError('Error fetching genre or subgenre.');
          setLoading(false);
        }
      } else {
        setError('Invalid story path.');
        setLoading(false);
      }
    };

    loadStoryProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId, genreId, subgenreId, user]);

  useEffect(() => {
    if (!loading && genre && subgenre && !story) {
      generateStory('', true);
      setLoading(false);
    }
  }, [loading, genre, subgenre, story]);

  // Function to handle choice selection
  const handleChoiceSelect = (choice) => {
    generateStory(choice);
  };

  // Function to extract choices from the text
  const extractChoicesFromText = (text) => {
    const choiceRegex = /\*\*Choice\s*\d+\*\*:\s*(.*)/gi;
    const choices = [];
    let match;
    while ((match = choiceRegex.exec(text)) !== null) {
      const choiceText = match[1].trim();
      // Check if the choice is complete
      if (choiceText && !choiceText.endsWith('...')) {
        choices.push(choiceText);
      }
    }
    console.log('AI Response:', text);
    return choices;
  };

  // Function to save story progress
  const saveStoryProgress = async () => {
    const storyData = {
      story,
      summary,
      chapterCount,
      title,
      isCompleted: isStoryCompleted,
      genre: {
        id: genre.id,
        name: genre.name,
      },
      subgenre: {
        id: subgenre.id,
        name: subgenre.name,
      },
      coverImage: null, // Placeholder for future use
      timestamp: new Date(),
    };
    if (user) {
      // Save to Firestore for authenticated users
      const userStoriesRef = firestore
        .collection('users')
        .doc(user.uid)
        .collection('stories');

      // Use a unique identifier for the story (e.g., storyId)
      let storyRef;
      if (storyId) {
        storyRef = userStoriesRef.doc(storyId);
        await storyRef.set(storyData, { merge: true });
      } else {
        storyRef = userStoriesRef.doc();
        await storyRef.set(storyData);
        navigate(`/story/${storyRef.id}`);
      }

      await storyRef.set(storyData, { merge: true });
    } else {
      // Save to localStorage for anonymous users
      localStorage.setItem('epicTalesStory', JSON.stringify(storyData));
    }
  };

  // Function to close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setError(null);
  };

  // Handle navigation back
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  // Function to handle rating change
  const handleRatingChange = async (event, newValue) => {
    setRating(newValue);

    if (user && storyId) {
      try {
        await firestore
          .collection('users')
          .doc(user.uid)
          .collection('stories')
          .doc(storyId)
          .update({
            rating: newValue,
          });
        // Optionally show a snackbar or message
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error submitting rating:', error);
        setError('Failed to submit rating. Please try again.');
        setSnackbarOpen(true);
      }
    } else {
      setError('You must be logged in to rate the story.');
      setSnackbarOpen(true);
    }
  };

  // **Conditional Rendering to Prevent Errors**
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (!genre || !subgenre) {
    return (
      <Typography variant="h6" color="error" textAlign="center" my={4}>
        {error || 'Loading...'}
      </Typography>
    );
  }

  return (
    <Box maxWidth={600} mx="auto" mt={4}>
      <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        {title ? title : "Generating your story..."}
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        gutterBottom
        sx={{ textAlign: "center" }}
      >
        Genre: {genre.name} - Subgenre: {subgenre.name}
      </Typography>

      {/* Progress Indicator */}
      <Box sx={{ width: "100%", marginY: 2 }}>
        <LinearProgress
          variant="determinate"
          value={Math.min((chapterCount / 10) * 100, 100)}
        />
      </Box>

      <Typography
        variant="body1"
        paragraph
        sx={{
          whiteSpace: "pre-line",
          color: "text.primary",
          fontSize: 16,
        }}
      >
        {story}
      </Typography>

      {isLoading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {!isLoading && !isStoryCompleted && choices.length > 0 && (
        <Box mt={3}>
          {choices.map((choice, index) => (
            <Button
              key={index}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleChoiceSelect(choice)}
              sx={{
                marginY: 1,
                textTransform: "none",
                transition: "background-color 0.3s, transform 0.2s",
                "&:hover": {
                  backgroundColor: "primary.dark",
                  transform: "scale(1.02)",
                },
              }}
            >
              {choice}
            </Button>
          ))}
        </Box>
      )}

      {!isLoading && choices.length === 0 && !isStoryCompleted && (
        <Box mt={3}>
          <Typography variant="body1" color="text.secondary">
            Sorry, no choices were provided. Please try again.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => generateStory("")}
            sx={{ mt: 2 }}
          >
            Retry
          </Button>
        </Box>
      )}

      {isStoryCompleted && (
        <>
          <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
            The End.
          </Typography>

          {/* Rating Component */}
          <Box mt={2} textAlign="center">
            <Typography variant="h6" gutterBottom>
              Rate this story:
            </Typography>
            <Rating
              name="story-rating"
              value={rating}
              onChange={handleRatingChange}
            />
            {rating > 0 && (
              <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
                Thank you for your rating!
              </Typography>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
            sx={{ mt: 2 }}
          >
            Start New Story
          </Button>
        </>
      )}

      {/* Start New Story Button (Optional) */}
      {!isStoryCompleted && (
        <>
          <Box mt={2} textAlign="center">
            <Button
            variant="outlined"
            color="secondary"
            onClick={handleOpenDialog}
            sx={{ mt: 2 }}
          >
            Start New Story
          </Button>
          </Box>
          <Box mt={2} textAlign="center">
          <DonateButton storyId={storyId} />
          </Box>
        </>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle id="confirm-dialog-title">Start New Story</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to start a new story? Your current progress
            will be lost.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStartNewStory}
            color="primary"
            autoFocus
          >
            Yes, Start New Story
          </Button>
        </DialogActions>
      </Dialog>

      {/* Social Sharing Buttons */}
      <Box mt={4} display="flex" justifyContent="center" gap={2}>
        <FacebookShareButton
          url={shareUrl}
          quote="Check out my story on Epic Tales!"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title="Check out my story on Epic Tales!"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        {/* Add more social platforms as needed */}
      </Box>

      {/* Error Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || "Your rating has been updated!"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Story;
