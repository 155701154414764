// src/CookieNotice.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';

const CookieNotice = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        bgcolor: 'background.paper',
        p: 2,
        boxShadow: 3,
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" display="inline">
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAccept} sx={{ ml: 2 }}>
        Accept
      </Button>
    </Box>
  );
};

export default CookieNotice;
