// src/AppRoutes.js

import React, { lazy, } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Library from './Library';
import Story from './Story';
import GenreList from './GenreList';
import SubgenreList from './SubgenreList';
import Signup from './Signup';
import Login from './Login';
import Donate from './DonateButton';
import DonationSuccess from './DonationSuccess';
import DonationCancel from './DonationCancel';
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfService = lazy(() => import('./TermsOfService'));

const AppRoutes = ({ user }) => (
  <Routes>
    <Route path="/" element={<Home user={user} />} />
    <Route path="/library" element={<Library user={user} />} />
    <Route path="/genres" element={<GenreList />} />
    <Route path="/subgenres/:genreId" element={<SubgenreList />} />
    <Route path="/story/:storyId" element={<Story user={user} />} />
    <Route path="/story/:genreId/:subgenreId" element={<Story user={user} />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/login" element={<Login />} />
    <Route path="/donate" element={<Donate />} />
    <Route path="/donation-success" element={<DonationSuccess />} />
    <Route path="/donation-cancel" element={<DonationCancel />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />
    {/* Add other routes as needed */}
  </Routes>
);

export default AppRoutes;