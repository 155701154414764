// src/App.js

import React, { useState, useEffect, Suspense, } from 'react';
import { auth } from './firebase';
import Header from './Header';
import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getDesignTokens from './theme';
import Footer from './Footer';
import { BrowserRouter as Router, } from 'react-router-dom';
import CookieNotice from './CookieNotice';
import AppRoutes from './AppRoutes';

function App() {
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState('light');

  const theme = createTheme(getDesignTokens(mode));

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    // Set up an authentication state observer
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    // Clean up the observer on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Header stays at the top for all pages */}
        <Header user={user} toggleColorMode={toggleColorMode} />
        {/* Add margin-top to offset fixed header if necessary */}
        <Container
          maxWidth="lg"
          sx={{
            mt: { xs: 3, sm: 3 },
            px: { xs: 2, sm: 3 },
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AppRoutes user={user} />
          </Suspense>
        </Container>
        <CookieNotice />
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
