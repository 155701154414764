// src/DonateButton.js

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Button } from '@mui/material';

const stripePromise = loadStripe('pk_test_51MydzqJNjqMaduwruz29MsAkYmTuX46sO5jbbGeM2w8uzMirfUnIuMcShCwmNrqtDiaD5nLTkaf8ZUf4z2WXyomj00YJoK5CAZ');

const DonateButton = ({ storyId }) => {
  const handleClick = async () => {
    const stripe = await stripePromise;

    // Create a new Checkout Session with the storyId
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
      { storyId }
    );

    const { id } = response.data;

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: id,
    });

    if (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Donate $0.99 to Support Us
    </Button>
  );
};

export default DonateButton;
