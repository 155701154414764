// src/Footer.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import DonateButton from './DonateButton';
import { BoySharp } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      component="footer"
      py={4}
      textAlign="center"
      sx={{ mt: 8, backgroundColor: "background.paper" }}
    >
      <Typography variant="body2" color="text.secondary">
        © {new Date().getFullYear()} Epic Tales. All rights reserved.
      </Typography>
      <Box mt={2}>
        <Link href="/privacy-policy" underline="hover" sx={{ mx: 1 }}>
          Privacy Policy
        </Link>
        |
        <Link href="/terms-of-service" underline="hover" sx={{ mx: 1 }}>
          Terms of Service
        </Link>
        |
        <Box mt={2} textAlign="center">
        <DonateButton />
        </Box>  
      </Box>
    </Box>
  );
};

export default Footer;
