// src/SubgenreList.js

import React, { useState, useEffect } from 'react';
import { firestore } from './firebase';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const SubgenreList = () => {
  const { genreId } = useParams();
  const [subgenres, setSubgenres] = useState([]);
  const [genre, setGenre] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubgenres = async () => {
      try {
        // Fetch the selected genre's details
        const genreDoc = await firestore.collection('genres').doc(genreId).get();
        if (genreDoc.exists) {
          setGenre({ id: genreDoc.id, ...genreDoc.data() });

          // Fetch subgenres from the subcollection
          const subgenresSnapshot = await firestore
            .collection('genres')
            .doc(genreId)
            .collection('subgenres')
            .get();

          const subgenresData = subgenresSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSubgenres(subgenresData);
        } else {
          console.error('Genre not found');
        }
      } catch (error) {
        console.error('Error fetching subgenres:', error);
      }
    };

    fetchSubgenres();
  }, [genreId]);

  const handleSubgenreClick = (subgenreId) => {
    navigate(`/story/${genreId}/${subgenreId}`);
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <Box>
      {/* Back Arrow */}
      <IconButton onClick={handleBackClick} sx={{ mb: 2 }}>
        <ArrowBackIcon />
      </IconButton>
      {genre && (
        <Typography variant="h4" gutterBottom>
          {genre.name} Subgenres
        </Typography>
      )}
      <Grid container spacing={2}>
        {subgenres.map((subgenre) => (
          <Grid item xs={12} sm={6} md={4} key={subgenre.id}>
            <Card>
              <CardActionArea onClick={() => handleSubgenreClick(subgenre.id)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={subgenre.imageUrl || "/placeholder.jpg"}
                  alt={subgenre.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {subgenre.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {subgenre.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SubgenreList;