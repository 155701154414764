// src/Home.js

import React, { useState, useRef } from 'react';
import About from './About';
import GenreList from './GenreList';
import SubgenreList from './SubgenreList';
import Story from './Story';
import { Box } from '@mui/material';

const Home = ({ user } = {}) => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedSubgenre, setSelectedSubgenre] = useState(null);

  const handleGenreSelect = (genre) => {
    setSelectedGenre(genre);
  };

  const handleSubgenreSelect = (subgenre) => {
    setSelectedSubgenre(subgenre);
  };

  const handleBackToGenres = () => {
    setSelectedGenre(null);
    setSelectedSubgenre(null);
  };

  const handleBackToSubgenres = () => {
    setSelectedSubgenre(null);
  };

  const handleStartNewStory = () => {
    setSelectedGenre(null);
    setSelectedSubgenre(null);
  };

  return (
    <Box sx={{ mt: 0 }}>
      {!selectedGenre ? (
        <>
          <About />
          <GenreList onSelectGenre={handleGenreSelect} />
        </>
      ) : !selectedSubgenre && selectedGenre.subgenres ? (
        <SubgenreList
          genre={selectedGenre}
          onSelectSubgenre={handleSubgenreSelect}
          onBack={handleBackToGenres}
        />
      ) : (
        <Story
          genre={selectedGenre}
          subgenre={selectedSubgenre}
          user={user}
          onBack={selectedSubgenre ? handleBackToSubgenres : handleBackToGenres}
          onStartNewStory={handleStartNewStory}
        />
      )}
    </Box>
  );
};

export default Home;
