// src/Login.js

import React, { useState } from 'react';
import { auth, facebookProvider, googleProvider } from './firebase';
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Container,
  Divider,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailLogin = async () => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      await auth.signInWithPopup(facebookProvider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await auth.signInWithPopup(googleProvider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Login to Epic Tales
        </Typography>
        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {/* Social Login Buttons */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          sx={{ mb: 2, textTransform: 'none' }}
        >
          Continue with Google
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<FacebookIcon />}
          onClick={handleFacebookLogin}
          sx={{
            mb: 2,
            textTransform: 'none',
            backgroundColor: '#3b5998',
            '&:hover': { backgroundColor: '#355089' },
          }}
        >
          Continue with Facebook
        </Button>

        <Divider sx={{ my: 3 }}>or</Divider>

        {/* Email/Password Login */}
        <TextField
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleEmailLogin}
          sx={{ mt: 2 }}
        >
          Login with Email
        </Button>

        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default Login;
