// src/About.js

import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import logo from './assets/full-logo.png'; // Adjust the path if necessary

const About = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 0, mb: 0, px: 2 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ pt: 2.5, pb: 3.75 }}
      >
        <Box
          component="img"
          src={logo}
          alt="Epic Tales Logo"
          sx={{ width: "350px", height: "auto", mb: 2 }}
        />
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Dive into a world where{" "}
          <strong>your choices shape the narrative</strong>. Epic Tales brings
          you personalized stories crafted by advanced AI, offering limitless
          adventures across a multitude of genres.{" "}
          <strong>Embark on epic journeys</strong>, make pivotal decisions, and
          experience stories like never before.{" "}
          <strong>Your saga awaits—are you ready to write it?</strong>
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
