// src/theme.js

import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: '#0D0D0D', // Darker blue/grey
    },
    secondary: {
      main: '#0095F6', // Instagram's accent blue
    },
    background: {
      default: mode === 'light' ? '#FFFFFF' : '#000000', // White or black
      paper: mode === 'light' ? '#FAFAFA' : '#121212', // Light grey or dark grey
    },
    text: {
      primary: mode === 'light' ? '#262626' : '#FFFFFF', // Dark grey or white
      secondary: '#8E8E8E', // Light grey for secondary text
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    // Adjust other typography settings as needed
  },
});

export default getDesignTokens;
